import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
	/************************根路径跳转************************/
	{
		path: '/',
		redirect: '/wechat/userlogin'
	},
	/*************************微信端开放页面***********************/
	{
		path: '/wechat/userlogin',
		component: () => import('../views/wechat/index/userlogin/index'),
		meta: {
			title: '用户授权登录',
			requireAuth: false,
			identity: 0
		}
	},
	{
	path: '/wechat/usertest',
	component: () => import('../views/wechat/index/usertest/index'),
		meta: {
			title: '用户测试登录',
			requireAuth: false,
			identity: 0
		}
	},
	/*************************微信端用户页面***********************/
	{
		path: '/wechatuser/home',
		component: ()=>import('../views/wechat/user/home/index'),
		meta:{
			title: '主页',
			requireAuth: true,
			identity: 1
		}
	},
	{
		path: '/wechatuser/questiondetail',
		component: ()=>import('../views/wechat/user/questiondetail/index'),
		meta:{
			title: '云端都护—百“杏”有话说',
			requireAuth: true,
			identity: 1
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

/**
 * to:即将要进入的目标对象
 * from:当前导航即将要离开的路由对象
 * next:调用该方法,才能进入下一个钩子
 * afterEach不需要调用next方法
 */
router.beforeEach((to, from, next) => {
	// 从vuex里面准备好身份和token令牌
	let token = store.state.globalData.token
	let identity = store.state.globalData.identity
	let expireTime = store.state.globalData.expireTime
	let nowTime = Date.parse(new Date()) / 1000
	// 路由发生变化修改页面title
	if (to.meta.title) {
		document.title = to.meta.title
	}
	// 判断该路由是否需要登录权限
	if (to.meta.requireAuth) {
		// 判断本地是否存在token
		if (!token) { 
			// 未登录，跳转登录页面，记录下初始访问的地址
			localStorage.setItem('redirectUrl',to.href)
			next({
				path: '/'
			})
			return
		}
		if (nowTime >= expireTime) {
			// 身份信息已经过期，跳转登录页面，记录下初始访问的地址
			localStorage.setItem('redirectUrl',to.href)
			next({
				path: '/'
			})
			return
		}
		if (to.meta.identity != 0 && (!identity || identity != to.meta.identity)) {
			// 身份有问题，跳转登录页面，记录下初始访问的地址
			localStorage.setItem('redirectUrl',to.href)
			next({
				path: '/'
			})
			return
		}
	}
	next()
})
export default router
