import { createStore } from 'vuex'

export default createStore({
  debug: true,
  state: {
	  //这里放全局参数
	  globalData:{
		  identity:localStorage.getItem("identity"),
		  token:localStorage.getItem("token"),
		  expireTime:localStorage.getItem("expireTime"),
		  wechatConfig:{
			  appId:"wx3e0ad8b30b56b1ce"
		  }
	  }
  },
  mutations: {
	 //这里是set方法
	 setIdentity:(state, identity) => {
		state.globalData.identity = identity
	 },
	 setToken:(state, token) => {
	 	state.globalData.token = token
	 },
	 setExpireTime:(state, expireTime) => {
	 	state.globalData.expireTime = expireTime
	 }
  },
  actions: {
	  //这里是get方法
  },
  modules: {
	  //store的子模块，内容就相当于是store的一个实例。调用方式和前面介绍的相似，只是要加上当前子模块名
  }
})
