import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueWechatTitle from 'vue-wechat-title'

import { Dialog,Toast,Notify } from 'vant'
import 'vant/es/dialog/style'
import 'vant/es/toast/style'
import 'vant/es/notify/style'

createApp(App).use(store).use(router).use(vueWechatTitle).use(Dialog).use(Toast).use(Notify).mount('#app')
